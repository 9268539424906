























































































































































































































































import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import Recorder from "@/core/utils/videoRecorder";
import { Presentation, Recording, SlideTimestamp } from "@/core/models";
import ImageSlider from "@/components/common/ImageSlider.vue";
import LangSwitcher from "@/views/recorder/components/LangSwitcher.vue";
import VolumeTester from "@/views/recorder/components/volume-tester/VolumeTester.vue";
import DeviceSelect from "@/views/recorder/components/DeviceSelect.vue";
import LocalStorage from "@/core/utils/LocalStorage";
import { Action } from "vuex-class";

// todo translate
@Component({
  computed: {
    LocalStorage() {
      return LocalStorage;
    },
  },
  components: {
    LangSwitcher,
    VolumeTester,
    ImageSlider,
    DeviceSelect,
  },
})
export default class VideoRecorder extends Vue {
  @Prop({ default: () => false }) isTimed!: boolean;
  @Prop({ default: () => 0 }) expectedLen!: number;
  @Prop({ default: () => 0 }) maxLen!: number;
  @Prop({ default: () => false }) loading!: boolean;
  @Prop({ default: () => null }) presentation!: Presentation | null;
  @PropSync("isRecordingProp") isRecording!: boolean;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  recorder: Recorder = new Recorder();
  video: HTMLVideoElement | undefined;
  blob: Blob | undefined;
  isTooBig = false;
  isPaused = false;
  isActive = false;
  flipped = false;
  recorderLocale = this.$i18n.locale;
  muted = false;

  // time controls
  elapsedTime = 0;
  interval: any = null;
  timeout: any = null;

  startTimer() {
    this.interval = setInterval(() => {
      this.elapsedTime += 0.1;
    }, 1 * 100);
  }
  stopTimer() {
    clearInterval(this.interval);
  }
  startTimeout() {
    this.timeout = setTimeout(
      this.stop,
      (this.maxLen - this.elapsedTime) * 1000,
    );
  }
  stopTimeout() {
    clearTimeout(this.timeout);
  }

  // recorder controls
  async start() {
    if (!this.video) return;
    const stream = await this.recorder.start(
      {
        onError: (errorMessage: string) => this.displaySnackbar(errorMessage),
      },
      true,
    );
    if (!stream) return;
    this.video.removeAttribute("src");
    this.video.srcObject = stream;
    this.video.play();
    this.video.muted = true;
    this.video.controls = false;
    this.isRecording = true;
    this.isActive = true;
    this.startTimer();
    if (this.isTimed) this.startTimeout();
  }
  pause() {
    this.recorder.pause();
    this.isPaused = true;
    this.stopTimer();
    if (this.isTimed) this.stopTimeout();
  }
  resume() {
    this.recorder.resume();
    this.isPaused = false;
    this.startTimer();
    if (this.isTimed) this.startTimeout();
  }
  stop() {
    this.recorder.stop().then(blob => {
      if (!this.video || !blob) return;
      this.blob = blob.videoBlob;
      this.video.muted = false;
      (this.video.srcObject as null | MediaStream) = null;
      this.video.controls = true;
      this.video.src = URL.createObjectURL(this.blob!);
      this.isRecording = false;
      this.isPaused = false;
      if (this.isTimed) this.stopTimeout();
      this.stopTimer();
      this.elapsedTime = 0;
      this.shouldReset = true;
    });
  }
  reset() {
    this.recorder.stop();
    this.recorder.reset();
    this.isActive = false;
    if (!this.video) return;
    this.video.muted = true;
    this.video.pause();
    this.video.currentTime = 0;
    this.video.removeAttribute("src");
    (this.video.srcObject as null | MediaStream) = null;
    this.isRecording = false;
    this.shouldReset = true;
    this.stopTimer();
    this.stopTimeout();
    this.elapsedTime = 0;
    this.slideTimestamps = [];
  }
  toggleMuted() {
    this.recorder?.toggleMuted();
    this.muted = !this.muted;
  }

  // upload controller
  uploading = false;
  askForFeedback = false;

  async upload() {
    if (!this.blob) return;

    const maxSize = 400000000;
    // const maxSize = 4000;

    if (this.blob.size > maxSize) {
      this.isTooBig = true;
      return;
    }

    this.uploading = true;
    try {
      const now = new Date();
      const name = `${this.$t("rec.prefix")} ${now.toLocaleString()}`;

      // make data
      const data = new FormData();
      data.append("file", this.blob);
      data.append("title", name);
      data.append("recordedAt", now.toISOString());
      data.append("locale", this.recorderLocale);
      data.append("timestamps", JSON.stringify(this.slideTimestamps));
      if (this.presentation)
        data.append("presentationId", JSON.stringify(this.presentation.ID));
      data.append("type", "video");

      console.log("upload. presentation=", JSON.stringify(this.presentation));
      console.log(
        "upload. presentationId=",
        JSON.stringify(this.presentation?.ID),
      );
      console.log("upload. data=", JSON.stringify(data));

      // send request
      const res = (await api.post("/api/Recordings", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })) as any;

      // create recording
      const rec: Recording = {
        id: res.id,
        blob: null,
        audioBlob: null,
        videoBlob: null,
        videoURI: res.videoURI,
        title: name,
        type: "video",
        locale: this.recorderLocale as any,
        recordedAt: now.toISOString(),
        slideTimestamps: this.slideTimestamps,
        presentationId: this.presentation?.ID,
      };

      // update list
      this.$emit("created", rec);
    } catch (error) {
      console.log(error);
    }

    this.uploading = false;
    this.reset();

    this.$router.push("/sessions/list");
  }

  // presentations controller
  shouldReset = false;
  slideIndex = 0;
  tsIndex = 0;
  slideTimestamps: SlideTimestamp[] = [];
  get images() {
    if (!this.presentation) return [];
    return this.presentation.Slides.map(x => x.Uri);
  }
  addSlide() {
    if (!this.recorder || !this.presentation) return;

    if (this.slideTimestamps.length > 0) {
      const lastTimestamp = this.slideTimestamps[
        this.slideTimestamps.length - 1
      ];
      lastTimestamp.duration = Math.max(
        this.elapsedTime * 1000 - lastTimestamp.offset,
        0,
      );
    }

    const slide = this.presentation.Slides[this.slideIndex];
    const ts: SlideTimestamp = {
      index: this.tsIndex,
      slideURI: slide.Uri,
      slideIndex: this.slideIndex,
      offset: this.elapsedTime * 1000,
      duration: 0,
    };

    this.slideTimestamps.push(ts);
    this.tsIndex++;
  }
  slideChanged(idx: number) {
    this.slideIndex = idx;
    this.addSlide();
  }
  @Watch("presentation")
  selectedChanged() {
    this.slideIndex = 0;
    this.addSlide();
  }

  mounted() {
    this.video = document.getElementById("video") as HTMLVideoElement;
  }

  // dialogs: record limit
  isRecordLimitWarning = false;
  recordLimitCallback: () => void = () => null;
  recordLimitWarningChecked = !LocalStorage.getRecordLimitDialog();

  setRecordLimitWarningChecked(checked: boolean) {
    LocalStorage.setRecordLimitDialog(!checked);
    this.recordLimitWarningChecked = checked;
  }

  toggleRecordLimitWarning(visible: boolean, confirmationCallback: () => void) {
    this.recordLimitWarningChecked = !LocalStorage.getRecordLimitDialog();
    this.isRecordLimitWarning = visible;
    this.recordLimitCallback = confirmationCallback;
  }
}
