









































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class UploadFileButton extends Vue {
  @Prop({ default: () => "*/**" }) accept!: string;
  @Prop({ default: () => false }) loading!: boolean;
  @Prop({ default: () => true }) canSubmit!: boolean;

  file: any = null;
  isSelectingFile = false;

  // Add video from file
  get formatSelectedFile() {
    let format = "";
    if (this.file) {
      let name = this.file.name;
      let size = this.formatFileSize(this.file.size);
      format = `${name} (${size} MB)`;
    }
    return format;
  }
  formatFileSize(size: number) {
    return Math.round((size / Math.pow(2, 20)) * 100) / 100;
  }
  selectFile() {
    this.isSelectingFile = true;
    (this.$refs.uploader as any).click();
  }
  unselectFile() {
    this.file = null;
    this.isSelectingFile = false;
    (this.$refs.uploader as any).value = "";
    this.$emit("file-changed", this.file);
  }
  onFileChanged(e: any) {
    let file = e.target.files[0];
    this.file = file;
    this.isSelectingFile = false;
    this.$emit("file-changed", this.file);
  }
  callSubmit() {
    this.$emit("submit", this.file);
    this.unselectFile();
  }

  handleFocus() {
    this.isSelectingFile = false;
    this.$forceUpdate();
  }
  created() {
    window.addEventListener("focus", this.handleFocus);
  }
  destroyed() {
    window.removeEventListener("focus", this.handleFocus);
  }
}
